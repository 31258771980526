import { DxContainer, DxGrid, DxSpacer } from '@dvag/design-system-react';

import { useParams } from 'react-router-dom';
import { usePersonList } from 'hooks/usePersonList';

import { WishAndGoalSalesforceCard } from './WishAndGoalSalesforceCard';
import './style.css';

export const WishAndGoalSalesforce = () => {
  const { personId: paramPersonId } = useParams();
  const { data: personList, isFetched } = usePersonList();
  const wgPersonList = paramPersonId
    ? personList.filter((i) => i.id === paramPersonId)
    : personList
        .sort((a, b) => {
          const dateA = a.birthDate ? new Date(a.birthDate).getTime() : null;
          const dateB = b.birthDate ? new Date(b.birthDate).getTime() : null;

          if (!dateA && !dateB) return 0;
          if (!dateA) return 1;
          if (!dateB) return -1;

          return dateA - dateB;
        })
        .slice(0, 2);

  const mqGrid = paramPersonId || personList.length === 1 ? '12/*' : '6-6/*';

  if (isFetched && !wgPersonList.length) throw Error('Not Found', { cause: 404 });

  return (
    <DxContainer color="transparent" data-testid="wg-salesforce-page-container" type="page">
      <DxSpacer size="16v" />
      <DxGrid mq1="12/*" mq2={mqGrid} data-testid="wg-salesforce-page-grid">
        {wgPersonList.map((person) => (
          <WishAndGoalSalesforceCard person={person} />
        ))}
      </DxGrid>
      <DxSpacer size="16v" />
    </DxContainer>
  );
};
