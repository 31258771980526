import type { FC } from 'react';
import i18next from 'i18next';
import { DxCard, DxCardContent, DxCardHeader, DxIcon, DxText } from '@dvag/design-system-react';
import { PersonInHouseholdDto } from '../../graphql/generates';
import { useGetPersonWishGoalList } from '../WishAndGoal/service/useGetPersonWishGoalList';
import './style.css';

const translation = {
  pageTitle: i18next.t('wishesAndGoals.headerTitle'),
};

// TODO: take the labels from translation files when moving the component to ui-blocks
const WGLabel = {
  personalCredit: 'Privatkredit',
  income: 'Einkommen',
  childrenEducation: 'Aus- & Weiterbildung der Kinder',
  governmentFundingAndTaxSavings: 'Staatliche Förderungen & Steuerersparnisse',
  retirementProvision: 'Altersvorsorge',
  residentialProperty: 'Wohneigentum',
  privateWealthBuildup: 'Privater Vermögensaufbau',
  healthCareAndRiskProvision: 'Gesundheits- & Risikovorsorge',
  assetProtection: 'Vermögensabsicherung',
  other: 'Gibt es weitere Wünsche und Ziele, die Ihnen wichtig sind?',
};

type WGLabelType = keyof typeof WGLabel;

export const WishAndGoalSalesforceCard: FC<{ person: PersonInHouseholdDto }> = ({ person }) => {
  const { id: personId, firstName, lastName } = person;
  const { data: wgList } = useGetPersonWishGoalList(personId);

  const desiredWGList = wgList.filter((i) => i.isDesired);

  return (
    <DxCard data-testid="wg-salesforce-card">
      <DxCardHeader label={translation.pageTitle}>
        <DxText slot="action">{`${firstName} ${lastName}`}</DxText>
      </DxCardHeader>

      <DxCardContent>
        {!desiredWGList?.length && (
          <DxText data-testid="wg-salesforce-empty" type="it" color="gray-33">
            Keine Wünsche und Ziele vorhanden
          </DxText>
        )}

        {desiredWGList?.map((item, index: number) => (
          <div
            key={item.name}
            data-testid={`W&G-info-${index}`}
            id={`W&G-info-${index}`}
            style={{ borderTop: index !== 0 ? '1px solid #CCCCCC' : '' }}
            className="flex"
          >
            <div className="flex wg-icon-container">
              {index < 3 && (
                <div className="wg-icon">
                  <DxIcon icon="stern-ausgefuellt" size={24} color="gray-66" />
                </div>
              )}
            </div>

            <div className="flex wg-list-item">
              <div className="flex">
                <DxText color="gray-83" type="it">
                  {item.name ? WGLabel[item.name as WGLabelType] : ''}
                </DxText>
              </div>
              {item.comment && (
                <div className="flex wg-list-item-comment">
                  <div>
                    <DxIcon icon="sprechblase" size={16} color="black" />
                  </div>
                  <div>
                    <DxText className="dots-style" color="gray-66" type="its">
                      {item.comment}
                    </DxText>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </DxCardContent>
    </DxCard>
  );
};
